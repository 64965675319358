import {inject, Injectable} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {
  BandmanagerRestApiAuthenticationService,
  BandmanagerRestApiBand,
  BandmanagerRestApiLogo,
  BandmanagerRestApiMyBandService,
  BandmanagerRestApiMyUniformService,
  BandmanagerRestApiProfileDto,
  BandmanagerRestApiSuccessDto,
  BandmanagerRestApiUniformPartTypeEnum,
  BandmanagerRestApiUniformPartValueEnum,
} from '@digitale-menschen/bandmanager-rest-api';
import {Store} from '@ngxs/store';
import {OnboardingState} from '../../shared/onboarding-state/onboarding.state';
import {UserStateActions} from '../../shared/user-state/user-state.actions';
import {OnboardingStateModel} from '../../shared/onboarding-state/onboarding-state.models';
import {
  BandmanagerRestApiCreateUniformDto,
} from '@digitale-menschen/bandmanager-rest-api/src/models/bandmanager-rest-api-create-uniform-dto';
import {environment} from '../../environments/environment';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import {LoadingService} from './loading.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from './alert.service';
import {ImageProcessingService} from "./image-processing.service";
import {DeviceState} from "../../shared/device-state/device.state";
dayjs.extend(minMax);


@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private lastSendEmailCallTimestamp: number = 0;
  private delayBetweenEmailCall = 30000;
  private apiAuthService = inject(BandmanagerRestApiAuthenticationService);
  private apiBandService = inject(BandmanagerRestApiMyBandService);
  private apiUniformService = inject(BandmanagerRestApiMyUniformService);
  private store = inject(Store);
  private loadingService = inject(LoadingService);
  private translateService = inject(TranslateService);
  private alertService = inject(AlertService);
  private imageService = inject(ImageProcessingService);

  public createEmptyUniform(): BandmanagerRestApiCreateUniformDto {
    return {
      bandId: '',
      colorPrimary: this.imageService.createRandomColor(),
      colorSecondary: this.imageService.createRandomColor(),
      uniformParts: [
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Hat,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.HatNone,
          // any ID as it will be replaced in API
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Tie,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.TieNone,
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Jacket,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.JacketNone,
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Pants,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.PantsLong,
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Shirt,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.ShirtLong,
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
        {
          uniformPartType: BandmanagerRestApiUniformPartTypeEnum.Shoes,
          uniformPartValue: BandmanagerRestApiUniformPartValueEnum.ShoesSneaker,
          uniformId: 'b59c9d2d-64c5-4d9d-ab08-39825267b196',
        },
      ],
    };
  }

  public async sendOnboardingToApi(): Promise<boolean> {
    const onboardingData = this.store.selectSnapshot(OnboardingState.onboardingData);
    if (!this.isOnboardingDataReady(onboardingData)) {
      return false;
    }

    try {
      await this.setUserInApi(onboardingData);
      await this.setBandInApi(onboardingData);
      // will trigger all loadings of data in the different States
      this.store.dispatch(new UserStateActions.SetIsLoggedIn(true));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Test if the user should be blocked because of email not verified in time
   * If account-creation is older that now-24h, and still not verified, the account should be blocked
   * @param profile
   */
  public checkProfileForEmailVerifyIsOk(profile: BandmanagerRestApiProfileDto): boolean {
    if (environment.emailVerifyBlocker.enabled && profile && !profile.user.isConfirmed) {
      const maxOldestDateOfCreation = dayjs().subtract(environment.emailVerifyBlocker.delayInHours, 'hour');
      let mostRecentDate = dayjs(profile.user.createdAt);
      if (profile.user.emailChangeAt) {
        mostRecentDate = dayjs.max(dayjs(profile.user.createdAt), dayjs(profile.user.emailChangeAt));
      }
      if (mostRecentDate.isBefore(maxOldestDateOfCreation)) {
        return false;
      }
    }
    return true;
  }

  public resendEmail(email: string): void {
    const nowTimestamp = Date.now();
    if (email &&
      (!this.lastSendEmailCallTimestamp || (this.lastSendEmailCallTimestamp && nowTimestamp > this.lastSendEmailCallTimestamp + this.delayBetweenEmailCall))) {
      this.loadingService.display();
      this.lastSendEmailCallTimestamp = nowTimestamp;
      lastValueFrom(this.apiAuthService.authenticationControllerResendRegisterEmail({body: {email: email}})).then(() => {
        const confirmMessage = this.translateService.instant('onboarding.verify-email-resend-success');
        this.alertService.display('success', confirmMessage);
      }).catch((error) => {
        this.alertService.display('error', error.message);
      }).finally(() => {
        this.loadingService.hide();
      });
    }
  }

  private isOnboardingDataReady(onboardingData: OnboardingStateModel): boolean {
    if (onboardingData) {
      if (onboardingData.bandUniform &&
        onboardingData.username &&
        onboardingData.email &&
        onboardingData.bandName &&
        onboardingData.countryId &&
        onboardingData.password) {
        return true;
      }
    }
    console.log('onboardingData are incomplete, we cannot proceed and send data to API, to create account & band');
    return false;
  }

  private async setUserInApi(onboardingData: OnboardingStateModel): Promise<void> {
    await this.createAccount(<string>onboardingData.email, <string>onboardingData.password);
    await this.doLogin(<string>onboardingData.email, <string>onboardingData.password);
    await this.setUsername(<string>onboardingData.email, <string>onboardingData.username);
    this.store.dispatch(new UserStateActions.SetEmail(<string>onboardingData.email));
  }

  private async setBandInApi(onboardingData: OnboardingStateModel): Promise<void> {
    const band = await this.createBand(<string>onboardingData.bandName, <string>onboardingData.countryId);
    if (band && onboardingData.bandUniform) {
      onboardingData.bandUniform.bandId = band.id;
      await this.setUniform(onboardingData.bandUniform);
    }
    if (onboardingData.bandLogo) {
      await this.addLogoToBand(band.id, onboardingData.bandLogo);
    }
  }

  /**
   * Do login
   * the username can also be the email
   * @param username
   * @param password
   * @private
   */
  private doLogin(username: string, password: string): Promise<BandmanagerRestApiSuccessDto> {
    return new Promise((resolve, reject) => {
      const deviceInfo = this.store.selectSnapshot(DeviceState.deviceInfos);
      lastValueFrom(this.apiAuthService.authenticationControllerLogin({
        body: {username, password, deviceId: <string>deviceInfo?.uniqueIdentifier},
      })).then(async (response) => {
        this.store.dispatch(new UserStateActions.SetAuthToken(response.access_token));
        if (response.refresh_token) {
          this.store.dispatch(new UserStateActions.SetRefreshToken(response.refresh_token));
        }
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  private createAccount(email: string, password: string): Promise<BandmanagerRestApiSuccessDto> {
    return lastValueFrom(this.apiAuthService.authenticationControllerRegister({
      body: {
        email: email,
        password: password,
      },
    }));
  }

  private setUsername(email: string, username: string): Promise<BandmanagerRestApiProfileDto> {
    return lastValueFrom(this.apiAuthService.authenticationControllerUpdateUserProfile({
      body: {
        email: email,
        username: username,
        sendConfirmationEmail: false,
      },
    }));
  }

  private createBand(name: string, countryId: string): Promise<BandmanagerRestApiBand> {
    return lastValueFrom(this.apiBandService.bandControllerCreateBand({
      body: {
        name: name,
        countryId: countryId,
      },
    }));
  }

  private addLogoToBand(bandId: string, imgBlob: Blob): Promise<BandmanagerRestApiLogo> {
    return lastValueFrom(this.apiBandService.logoControllerCreate({
      body: {
        file: imgBlob,
        bandId: bandId,
      },
    }));
  }

  private setUniform(newUniform: BandmanagerRestApiCreateUniformDto): Promise<boolean> {
    return lastValueFrom(this.apiUniformService.uniformControllerCreateUniform({body: newUniform}));
  }
}
