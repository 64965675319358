import {inject, Injectable} from '@angular/core';
import {BandmanagerRestApiMyDevicesService} from "@digitale-menschen/bandmanager-rest-api";
import {Store} from "@ngxs/store";
import {DeviceState} from "../../shared/device-state/device.state";
import {lastValueFrom, take} from "rxjs";
import {DeviceStateActions} from "../../shared/device-state/device-state.actions";
import {
  BandmanagerRestApiUpdateUserDeviceDto
} from "@digitale-menschen/bandmanager-rest-api/src/models/bandmanager-rest-api-update-user-device-dto";
import {Capacitor} from '@capacitor/core';
import {Device} from '@capacitor/device';
import {App} from '@capacitor/app';
import {DeviceInfosInterface} from "../models/device-infos.interface";
import {FirebaseMessaging} from "@capacitor-firebase/messaging";
import {UserState} from "../../shared/user-state/user.state";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private apiDeviceService = inject(BandmanagerRestApiMyDevicesService);
  private store = inject(Store);

  private async updateDeviceInformation(): Promise<DeviceInfosInterface> {
    let appInfo = {version: '1.0.0'};
    if (Capacitor.isNativePlatform()) {
      appInfo = await App.getInfo();
    }
    const deviceId = await Device.getId();
    const deviceInfo = await Device.getInfo();
    let pushToken: string | null = null;
    try {
      pushToken = await this.updatePushTokenForPreferences();
    } catch (error) {
      console.error("error updating push token", error);
    }

    const infos: DeviceInfosInterface = {
      model: deviceInfo.model,
      osVersion: deviceInfo.osVersion,
      platform: deviceInfo.platform,
      uniqueIdentifier: deviceId.identifier,
      appVersion: appInfo.version,
      pushNotificationToken: pushToken
    }

    this.store.dispatch(new DeviceStateActions.SetDeviceInfos(infos));

    return infos;
  }

  private async updatePushTokenForPreferences(): Promise<string | null> {
    const existingDeviceInfos = this.store.selectSnapshot(DeviceState.deviceInfos);
    const pushNotificationsEnabled = this.store.selectSnapshot(UserState.pushNotifications);
    let token: string | null = existingDeviceInfos?.pushNotificationToken || null;

    if (pushNotificationsEnabled) {
      if (!existingDeviceInfos?.pushNotificationToken) {
        const tokenObj = await FirebaseMessaging.getToken({vapidKey: environment.firebase_config.vapidKey});
        token = tokenObj.token;
      }
    } else {
      // unsusbcribe
      if (existingDeviceInfos?.pushNotificationToken) {
        await FirebaseMessaging.deleteToken();
        token = null;
      }
    }

    return token;
  }

  public async updateDevice(): Promise<void> {
    const deviceInfos = await this.updateDeviceInformation();

    const payload: BandmanagerRestApiUpdateUserDeviceDto = {
      appVersion: deviceInfos.appVersion,
      model: deviceInfos.appVersion,
      osVersion: deviceInfos.osVersion,
      platform: deviceInfos.platform,
      pushNotificationToken: deviceInfos.pushNotificationToken || undefined,
      uniqueIdentifier: deviceInfos.uniqueIdentifier
    };

    if (deviceInfos.uniqueIdentifier) {
      this.apiDeviceService.userDevicesControllerUpdateUserDevice({
        id: deviceInfos.uniqueIdentifier,
        body: payload
      }).pipe(take(1)).subscribe((userDevice) => {
        if (userDevice) {
          this.store.dispatch(new DeviceStateActions.SetDeviceInfos(userDevice));
        }
      });
    } else {
      this.apiDeviceService.userDevicesControllerRegisterUserDevice({
        body: payload
      }).pipe(take(1)).subscribe((userDevice) => {
        if (userDevice) {
          this.store.dispatch(new DeviceStateActions.SetDeviceInfos(userDevice));
        }
      });
    }
  }
}
