import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../../environments/environment";
import {CustomMissingTranslationHandler} from "./CustomMissingTranslationHandler.class";

/**
 * Create a custom translation loader
 * @param httpClient
 * @returns {TranslateHttpLoader}
 */
export function createTranslateLoader(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, environment.translation_path, '.json');
}

/**
 * Factory for CustomMissingTranslationHandler
 *
 * @returns {CustomMissingTranslationHandler}
 */
export function createCustomMissingTranslationHandler(): CustomMissingTranslationHandler {
  return new CustomMissingTranslationHandler();
}
