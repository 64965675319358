import {inject, Injectable} from '@angular/core';
import {UserState} from "../../shared/user-state/user.state";
import {lastValueFrom} from "rxjs";
import {UserStateActions} from "../../shared/user-state/user-state.actions";
import {Store} from "@ngxs/store";
import {BandmanagerRestApiAuthenticationService} from "@digitale-menschen/bandmanager-rest-api";
import {Router} from "@angular/router";
import {DeviceState} from "../../shared/device-state/device.state";

@Injectable({
  providedIn: 'root'
})
export class ApiSessionService {
  private store = inject(Store);
  private bandmanagerRestApiAuthenticationService = inject(BandmanagerRestApiAuthenticationService);
  private router = inject(Router);

  constructor() {
  }

  public async refreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      const refreshToken = this.store.selectSnapshot(UserState.refreshToken);
      const deviceId = this.store.selectSnapshot(DeviceState.deviceInfos)?.uniqueIdentifier;
      lastValueFrom(this.bandmanagerRestApiAuthenticationService.authenticationControllerRefreshToken({
        body: {
          refreshToken: <string>refreshToken,
          deviceId: <string>deviceId
        }
      }))
        .then((response) => {
          console.log("SESSION in refreshToken");
          if (response.refresh_token) {
            this.store.dispatch(new UserStateActions.SetRefreshToken((<any>response).refresh_token));
          }
          if (response.access_token) {
            this.store.dispatch(new UserStateActions.SetAuthToken((<any>response).access_token));
            this.store.dispatch(new UserStateActions.SetIsLoggedIn(response.success));
            console.log("SESSION in refreshToken, setting isLoggedin true");
          }
          resolve();
        }).catch((error) => {
        console.log("SESSION in refreshToken, error, setting isLoggedIn false");
        this.store.dispatch(new UserStateActions.SetIsLoggedIn(false));
        this.router.navigateByUrl("/auth/login").then();
        reject(error);
      });
    });
  }
}
