import {BandmanagerRestApiEventParticipation} from "@digitale-menschen/bandmanager-rest-api";

export namespace EventsStateActions {

  export class setEventsParticipations {
    static readonly type = '[EventsState] setEventsParticipations';

    constructor(public participations: BandmanagerRestApiEventParticipation[]) {
    }
  }
}
