import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {EventsStateActions} from './events-state.actions';
import {EventsStateModel} from "./events-state.model";
import {BandmanagerRestApiEventParticipation} from "@digitale-menschen/bandmanager-rest-api";

@State<EventsStateModel>({
  name: 'eventsState',
  defaults: {
    eventsParticipations: []
  }
})
@Injectable()
export class EventsState {

  @Selector()
  static eventsParticipations(state: EventsStateModel): BandmanagerRestApiEventParticipation[] {
    return state.eventsParticipations;
  }

  @Action(EventsStateActions.setEventsParticipations)
  setEventsParticipations(ctx: StateContext<EventsStateModel>, payload: EventsStateActions.setEventsParticipations) {
    ctx.patchState({
      eventsParticipations: payload.participations
    })
  }
}
