import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID, Provider, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { routes } from './app.routes';
import { ApiModule } from '@digitale-menschen/bandmanager-rest-api';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { UserState } from '../shared/user-state/user.state';
import { ApiInterceptor } from './services/api-interceptor.service';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { PersistentStorageEngine } from './classes/persistent-storage-engine.class';
import { BandState } from '../shared/band-state/band.state';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { AppState } from '../shared/app-state/app.state';
import { OnboardingState } from '../shared/onboarding-state/onboarding.state';
import { DeviceState } from '../shared/device-state/device.state';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EventsState } from '../shared/events-state/events.state';
import { createCustomMissingTranslationHandler, createTranslateLoader } from './classes/custom-translate-helper';
import { SplashScreen } from '@capacitor/splash-screen';

export function initializeApp(): () => Promise<void> {
  return () => SplashScreen.hide();
}

export function provideAppInitializer(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    deps: [],
    multi: true,
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useFactory: createCustomMissingTranslationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      ApiModule.forRoot({
        rootUrl: environment.apiConfiguration.baseUrl,
      }),
      NgxsModule.forRoot(
        [UserState, BandState, AppState, OnboardingState, DeviceState, EventsState],
        {
          // developmentMode: !environment.production,
          selectorOptions: {
            suppressErrors: false,
          },
        },
      ),
      NgxsStoragePluginModule.forRoot({ keys: ['UserState', 'BandState', 'DeviceState'] }),
    ),
    {
      provide: LOCALE_ID,
      useValue: 'en-EN',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: STORAGE_ENGINE,
      useClass: PersistentStorageEngine,
    },
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: { hasBackdrop: false },
    },
  ],
};
