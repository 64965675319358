import {Routes} from '@angular/router';
import {AuthGuardService} from "./guards/auth-guard.service";
import {NotLoggedInGuardService} from "./guards/not-loggedin-guard.service";

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-dashboard/page-dashboard.component').then(m => m.PageDashboardComponent),
    data: {animationPageName: 'home'}
  },
  {
    path: "auth",
    children: [
      {
        path: 'login',
        canActivate: [NotLoggedInGuardService],
        loadComponent: () => import('./pages/authentication/page-login/page-login.component').then(m => m.PageLoginComponent),
      },
      {
        // attention: url used in app.component
        path: 'verify',
        loadComponent: () => import('./pages/authentication/page-verify/page-verify.component').then(m => m.PageVerifyComponent),
      },
      {
        // attention: url used in app.component
        path: 'password-reset',
        loadComponent: () => import('./pages/authentication/page-password-reset/page-password-reset.component').then(m => m.PagePasswordResetComponent),
      }
    ]
  },
  {
    path: "onboarding",
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/onboarding/page-create-band/page-create-band.component').then(m => m.PageCreateBandComponent),
      },
      {
        path: 'logo',
        loadComponent: () => import('./pages/onboarding/page-logo/page-logo.component').then(m => m.PageLogoComponent),
      },
      {
        path: 'uniform',
        loadComponent: () => import('./pages/onboarding/page-uniform/page-uniform.component').then(m => m.PageUniformComponent),
      },
      {
        path: 'create-account',
        loadComponent: () => import('./pages/onboarding/page-create-account/page-create-account.component').then(m => m.PageCreateAccountComponent),
      },
      {
        path: 'username',
        loadComponent: () => import('./pages/onboarding/page-username/page-username.component').then(m => m.PageUsernameComponent),
      },
      {
        path: 'onboarding-loader',
        loadComponent: () => import('./pages/onboarding/page-onboarding-loader/page-onboarding-loader.component').then(m => m.PageOnboardingLoaderComponent),
      }
    ]
  },
  {
    path: 'my-band',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/my-band/page-my-band/page-my-band.component').then(m => m.PageMyBandComponent),
        data: {animationPageName: 'my-band'},
      },
      {
        path: 'instruments',
        loadComponent: () => import('./pages/my-band/page-band-instruments/page-band-instruments.component').then(m => m.PageBandInstrumentsComponent),
        data: {animationPageName: 'my-band-instruments'}
      },
      {
        path: 'logo',
        loadComponent: () => import('./pages/my-band/page-band-logo/page-band-logo.component').then(m => m.PageBandLogoComponent),
        data: {animationPageName: 'my-band-logo'}
      },
      {
        path: 'uniform',
        loadComponent: () => import('./pages/my-band/page-band-uniform/page-band-uniform.component').then(m => m.PageBandUniformComponent),
        data: {animationPageName: 'my-band-uniform'}
      },
      {
        path: 'bandroom',
        loadComponent: () => import('./pages/my-band/page-bandroom/page-bandroom.component').then(m => m.PageBandroomComponent),
        data: {animationPageName: 'my-band-bandroom'}
      },
      {
        path: 'players',
        children: [
          {
            path: '',
            loadComponent: () => import('./pages/my-band/page-band-players/page-players.component').then(m => m.PagePlayersComponent),
            data: {animationPageName: 'players'}
          }
        ]
      }
    ]
  },
  {
    path: 'events',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/page-events/page-events.component').then(m => m.PageEventsComponent),
        data: {animationPageName: 'events'},
      }
    ]
  },
  {
    path: 'ranking',
    canActivate: [AuthGuardService],
    loadComponent: () => import('./pages/page-ranking/page-ranking.component').then(m => m.PageRankingComponent),
    data: {animationPageName: 'ranking'},
  },
  {
    path: 'more',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/more/page-more/page-more.component').then(m => m.PageMoreComponent),
        data: {animationPageName: 'more'},
      },
      {
        path: 'feedback',
        loadComponent: () => import('./pages/more/page-feedback/page-feedback.component').then(m => m.PageFeedbackComponent),
        data: {animationPageName: 'feedback'}
      },
      {
        path: 'preferences',
        loadComponent: () => import('./pages/more/page-preferences/page-preferences.component').then(m => m.PagePreferencesComponent),
        data: {animationPageName: 'preferences'}
      },
      {
        path: 'ads',
        loadComponent: () => import('./pages/more/page-ads/page-ads.component').then(m => m.PageAdsComponent),
        data: {animationPageName: 'preferences'}
      },
      {
        path: 'profile',
        children: [
          {
            path: "",
            loadComponent: () => import('./pages/more/page-profile/page-profile.component').then(m => m.PageProfileComponent),
            data: {animationPageName: 'profile'}
          },
          {
            path: "change-username",
            loadComponent: () => import('./pages/more/page-change-username/page-change-username.component').then(m => m.PageChangeUsernameComponent),
            data: {animationPageName: 'change-username'}
          },
          {
            path: "change-email",
            loadComponent: () => import('./pages/more/page-change-email/page-change-email.component').then(m => m.PageChangeEmailComponent),
            data: {animationPageName: 'change-email'}
          },
          {
            path: "change-password",
            loadComponent: () => import('./pages/more/page-change-password/page-change-password.component').then(m => m.PageChangePasswordComponent),
            data: {animationPageName: 'change-password'}
          }
        ]
      },
    ]
  },
  {
    path: 'intro',
    loadComponent: () => import("./pages/page-intro/page-intro.component").then(m => m.PageIntroComponent),
  },

  /**
   * ROUTING FOR MODAL
   */
  {
    path: 'modal-intro',
    loadComponent: () => import("./pages/contents/content-intro/content-intro.component").then(m => m.ContentIntroComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-event-details',
    loadComponent: () => import("./pages/modals/modal-event-details/modal-event-details.component").then(m => m.ModalEventDetailsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-player-details',
    loadComponent: () => import("./pages/modals/modal-player-details/modal-player-details.component").then(m => m.ModalPlayerDetailsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-player-details/:id',
    loadComponent: () => import("./pages/modals/modal-player-details/modal-player-details.component").then(m => m.ModalPlayerDetailsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-band-details/:id',
    loadComponent: () => import("./pages/modals/modal-band-details/modal-band-details.component").then(m => m.ModalBandDetailsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-band-details',
    loadComponent: () => import("./pages/modals/modal-band-details/modal-band-details.component").then(m => m.ModalBandDetailsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-band-event-results/:bandId',
    loadComponent: () => import("./pages/modals/modal-band-event-results/modal-band-event-results.component").then(m => m.ModalBandEventResultsComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-band-players/:bandId',
    loadComponent: () => import("./pages/modals/modal-band-players/modal-band-players.component").then(m => m.ModalBandPlayersComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-uniform-parts',
    loadComponent: () => import("./pages/modals/modal-uniform-part/modal-uniform-part.component").then(m => m.ModalUniformPartComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-pass-forgotten',
    loadComponent: () => import("./pages/modals/modal-pass-forgotten/modal-pass-forgotten.component").then(m => m.ModalPassForgottenComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  },
  {
    path: 'modal-profile-blocked',
    loadComponent: () => import("./pages/modals/modal-profile-blocker/modal-profile-blocker.component").then(m => m.ModalProfileBlockerComponent),
    outlet: 'modal-router',
    data: {isModal: true}
  }
];
