import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {BandmanagerRestApiAuthenticationService} from '@digitale-menschen/bandmanager-rest-api';
import {Store} from '@ngxs/store';
import {UserStateActions} from '../../shared/user-state/user-state.actions';
import {AlertService} from './alert.service';
import {UserState} from "../../shared/user-state/user.state";
import {DeviceState} from "../../shared/device-state/device.state";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private alertService = inject(AlertService);
  private store = inject(Store);
  private bandmanagerRestApiAuthenticationService = inject(BandmanagerRestApiAuthenticationService);


  /**
   * Intercepts every HTTP request. It will turn on 'withCredentials' so that cookies are being sent to the API.
   * If a http 401 exception occurs, it will attempt to get new access and refresh token.
   * If this fails, it will update the UserState which can be used in the frontend to e.g. re-route, clear state etc.
   *
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const localAccessToken = this.store.selectSnapshot(UserState.accessToken);
    let requestC: any = null;

    if (localAccessToken) {
      let authToken = `Bearer ${localAccessToken}`;
      requestC = request.clone({
        setHeaders: {
          "Authorization": authToken
        }
      });
    } else {
      requestC = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(requestC).pipe(
      catchError(response => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          return this.handle401Error(requestC, next);
        } else {
          const httpErrorResponse = response as HttpErrorResponse;
          const errorText: string = (response.error && response.error.message) ? response.error.message : httpErrorResponse.statusText;
          this.alertService.display('error', errorText);
          throw response;
        }
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      const refreshToken = this.store.selectSnapshot(UserState.refreshToken);
      const deviceId = this.store.selectSnapshot(DeviceState.deviceInfos)?.uniqueIdentifier;

      /**
       * Get new access_token and refresh_token based on the current refresh_token.
       */
      return this.bandmanagerRestApiAuthenticationService.authenticationControllerRefreshToken({body: {refreshToken: <string>refreshToken, deviceId: <string>deviceId}}).pipe(
        switchMap((response) => {
          this.isRefreshing = false;
          if (response.refresh_token) {
            this.store.dispatch(new UserStateActions.SetRefreshToken((<any>response).refresh_token));
          }
          if (response.access_token) {
            this.store.dispatch(new UserStateActions.SetAuthToken((<any>response).access_token));
            this.store.dispatch(new UserStateActions.SetIsLoggedIn(response.success));
          }
          return next.handle(request);
        }),
        catchError(error => {
          // logout the user if refreshToken is not possible
          this.store.dispatch(new UserStateActions.SetIsLoggedIn(false));
          throw error;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
