import {inject, Injectable} from '@angular/core';
import {
  AdMob,
  AdmobConsentStatus,
  AdMobRewardItem,
  AdOptions,
  BannerAdOptions, BannerAdPluginEvents,
  BannerAdPosition,
  BannerAdSize,
  InterstitialAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents
} from '@capacitor-community/admob';
import {DeviceState} from "../../shared/device-state/device.state";
import {Store} from "@ngxs/store";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  private store = inject(Store);
  private isTesting = false;
  private bannerListener: any = null;
  private interstitialListener: any = null;
  private rewardListener: any = null;

  public async initialize(testing = false): Promise<void> {
    let deviceId = "";
    if (testing) {
      deviceId = this.store.selectSnapshot(DeviceState.deviceInfos)?.uniqueIdentifier || "";
      this.isTesting = true;
    }
    const payload = testing ? {
      // requestTrackingAuthorization: true,
      initializeForTesting: true,
      testingDevices: [deviceId]
    } : {requestTrackingAuthorization: true};
    await AdMob.initialize(payload);

    const [trackingInfo, consentInfo] = await Promise.all([
      AdMob.trackingAuthorizationStatus(),
      AdMob.requestConsentInfo(),
    ]);

    if (trackingInfo.status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/

      await AdMob.requestTrackingAuthorization();
    }

    const authorizationStatus = await AdMob.trackingAuthorizationStatus();
    if (
      authorizationStatus.status === 'authorized' &&
      consentInfo.isConsentFormAvailable &&
      consentInfo.status === AdmobConsentStatus.REQUIRED
    ) {
      await AdMob.showConsentForm();
    }
  }

  // UMP
  public async showConsent() {
    const consentInfo = await AdMob.requestConsentInfo();

    if (consentInfo.isConsentFormAvailable && consentInfo.status === AdmobConsentStatus.REQUIRED) {
      const {status} = await AdMob.showConsentForm();
    }
  }

  public async showBanner(position: "middle" | "bottom"): Promise<void> {
    if (!this.bannerListener) {
      this.bannerListener = await AdMob.addListener(
        BannerAdPluginEvents.AdImpression,
        () => {
          console.log("the banner has been viewed");
        },
      );
    }

    const options: BannerAdOptions = {
      adId: environment.ads.bannerId,
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: position === "middle" ? BannerAdPosition.CENTER : BannerAdPosition.BOTTOM_CENTER,
      // if bottom, we set it on top of main menu
      margin: position === "bottom" ? 80 : 0,
      isTesting: this.isTesting
      // npa: true
    };
    await AdMob.showBanner(options);
  }

  public async showInterstitial(): Promise<void> {
    console.log("this.interstitialListener", this.interstitialListener);
    if (!this.interstitialListener) {
      this.interstitialListener = await AdMob.addListener(InterstitialAdPluginEvents.Showed,
        () => {
          console.log("the interstitial has been viewed");
        },
      );
    }

    const options: AdOptions = {
      adId: environment.ads.interstitialId,
      isTesting: this.isTesting
      // npa: true
    };
    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  }

  public async showRewardVideo(): Promise<void> {
    console.log("this.rewardListener", this.rewardListener);
    if (!this.rewardListener) {
      this.rewardListener = await AdMob.addListener(
        RewardAdPluginEvents.Rewarded,
        (rewardItem: AdMobRewardItem) => {
          // Subscribe user rewarded
          console.log("the reward has been viewed", rewardItem);
        },
      );
    }

    const options: RewardAdOptions = {
      adId: environment.ads.reward,
      isTesting: this.isTesting
      // npa: true
      // ssv: {
      //   userId: "A user ID to send to your SSV"
      //   customData: JSON.stringify({ ...MyCustomData })
      //}
    };
    await AdMob.prepareRewardVideoAd(options);
    await AdMob.showRewardVideoAd();
  }

  public removeBanner(): void {
    AdMob.removeBanner().then();
  }
}
