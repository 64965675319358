@if (alertData) {
  <div class="app-alert"
       [class.close-alert-animation]="goingToClose"
       [class.app-alert--error]="alertData.type === 'error'"
       [class.app-alert--warning]="alertData.type === 'warning'"
       [class.app-alert--info]="alertData.type === 'info'"
       [class.app-alert--success]="alertData.type === 'success'"
       (click)="close()"
  >
    <div class="app-alert__content-wrapper glob-flex glob-flex--center">
      <div class="app-alert__content glob-flex glob-flex--gap glob-center glob-font-w600 glob-font-16">
        @switch (alertData.type) {
          @case ('error') {
            <img src="assets/icons/icon-error.svg" width="24" height="24" alt="">
          }
          @case ('success') {
            <img src="assets/icons/icon-success.svg" width="24" height="24" alt="">
          }
          @default {
            <img src="assets/icons/icon-warning.svg" width="24" height="24" alt="">
          }
        }
        <span>{{ alertData.text }}
          @if (alertData.func && alertData.funcLabel) {
            <br><a (click)="alertData.func()">{{ alertData.funcLabel }}</a>
          }</span>
      </div>
    </div>
  </div>
}
